import { ref } from 'vue'
import type { NodeList } from '~/types/node'

export const useSubtitleFile = () => {
  const supportedFileExtensions = ['srt', 'vtt'];
  const supportedFiles = '.srt, .vtt';

  const subtitles = useState<NodeList>('subtitles', () => []);

  const currentSubtitleFile = useState<File | null>('currentSubtitleFile', () => null)

  const setSubtitleFile = (file: File) => {
    currentSubtitleFile.value = file
  }

  const clearSubtitleFile = () => {
    currentSubtitleFile.value = null
  }

  const isValidSubtitleFile = (file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return fileExtension ? supportedFileExtensions.includes(fileExtension) : false;
  }

  const readFileAsString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          resolve(event.target.result as string);
        } else {
          reject(new Error('Không thể đọc file'));
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  const getSubtitles = () => {
    return subtitles.value;
  }

  const setSubtitles = (newSubtitles: NodeList) => {
    subtitles.value = newSubtitles;
  }

  return {
    currentSubtitleFile,
    supportedFileExtensions,
    supportedFiles,
    setSubtitleFile,
    clearSubtitleFile,
    isValidSubtitleFile,
    readFileAsString,
    getSubtitles,
    setSubtitles,
  }
}

